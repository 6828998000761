.cfx:after {
  content: "";
  display: table;
  clear: both;
}

.menu, .menu__item {
  margin: 0;
  padding: 0;
}
.menu__item {
  list-style-type: none;
}
.menu__link:hover {
  text-decoration: none;
}

.has-gray-bg {
  background: #f1f2f9;
}

.has-success-bg {
  position: relative;
  z-index: 1;
}
.has-success-bg:before {
  content: "";
  background: white url("../assets/icon-check-large.svg") center no-repeat;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  position: absolute;
  top: 0;
  left: 50%;
  margin: -105px 0 0 55px;
  z-index: -1;
}
@media all and (max-width: 767px) {
  .has-success-bg:before {
    width: 160px;
    height: 160px;
    background-size: 110px auto;
    margin: -55px 0 0 55px;
  }
}

.pos-center {
  margin: auto;
}

.wrapper {
  padding: 0 20px;
  margin: 0 auto;
}
.wrapper--xl {
  width: 1240px;
}
@media all and (max-width: 1280px) {
  .wrapper--xl {
    width: 100%;
    box-sizing: border-box;
  }
}
.wrapper--l {
  width: 820px;
}
@media all and (max-width: 860px) {
  .wrapper--l {
    width: 100%;
    box-sizing: border-box;
  }
}
.wrapper--m {
  width: 610px;
}
@media all and (max-width: 650px) {
  .wrapper--m {
    width: 100%;
    box-sizing: border-box;
  }
}
@media all and (max-width: 767px) {
  .wrapper {
    padding: 0 15px;
  }
}

.grid-1-xl {
  width: 8.3333333333%;
}

.grid-2-xl {
  width: 16.6666666667%;
}

.grid-3-xl {
  width: 25%;
}

.grid-4-xl {
  width: 33.3333333333%;
}

.grid-5-xl {
  width: 41.6666666667%;
}

.grid-6-xl {
  width: 50%;
}

.grid-7-xl {
  width: 58.3333333333%;
}

.grid-8-xl {
  width: 66.6666666667%;
}

.grid-9-xl {
  width: 75%;
}

.grid-10-xl {
  width: 83.3333333333%;
}

.grid-11-xl {
  width: 91.6666666667%;
}

.grid-12-xl {
  width: 100%;
}

.grid-1-l {
  width: 12.5%;
}

.grid-2-l {
  width: 25%;
}

.grid-3-l {
  width: 37.5%;
}

.grid-4-l {
  width: 50%;
}

.grid-5-l {
  width: 62.5%;
}

.grid-6-l {
  width: 75%;
}

.grid-7-l {
  width: 87.5%;
}

.grid-8-l {
  width: 100%;
}

.grid-1-m {
  width: 16.6666666667%;
}

.grid-2-m {
  width: 33.3333333333%;
}

.grid-3-m {
  width: 50%;
}

.grid-4-m {
  width: 66.6666666667%;
}

.grid-5-m {
  width: 83.3333333333%;
}

.grid-6-m {
  width: 100%;
}

@media all and (max-width: 1279px) {
  .medium-grid-6-xl {
    width: 50%;
  }
}
@media all and (max-width: 767px) {
  .mobile-grid-1-xl {
    width: 8.3333333333%;
  }

  .mobile-grid-2-xl {
    width: 16.6666666667%;
  }

  .mobile-grid-3-xl {
    width: 25%;
  }

  .mobile-grid-4-xl {
    width: 33.3333333333%;
  }

  .mobile-grid-5-xl {
    width: 41.6666666667%;
  }

  .mobile-grid-6-xl {
    width: 50%;
  }

  .mobile-grid-7-xl {
    width: 58.3333333333%;
  }

  .mobile-grid-8-xl {
    width: 66.6666666667%;
  }

  .mobile-grid-9-xl {
    width: 75%;
  }

  .mobile-grid-10-xl {
    width: 83.3333333333%;
  }

  .mobile-grid-11-xl {
    width: 91.6666666667%;
  }

  .mobile-grid-12-xl {
    width: 100%;
  }

  .mobile-grid-1-l {
    width: 12.5%;
  }

  .mobile-grid-2-l {
    width: 25%;
  }

  .mobile-grid-3-l {
    width: 37.5%;
  }

  .mobile-grid-4-l {
    width: 50%;
  }

  .mobile-grid-5-l {
    width: 62.5%;
  }

  .mobile-grid-6-l {
    width: 75%;
  }

  .mobile-grid-7-l {
    width: 87.5%;
  }

  .mobile-grid-8-l {
    width: 100%;
  }

  .mobile-grid-1-m {
    width: 16.6666666667%;
  }

  .mobile-grid-2-m {
    width: 33.3333333333%;
  }

  .mobile-grid-3-m {
    width: 50%;
  }

  .mobile-grid-4-m {
    width: 66.6666666667%;
  }

  .mobile-grid-5-m {
    width: 83.3333333333%;
  }

  .mobile-grid-6-m {
    width: 100%;
  }
}
.link {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #ff7800;
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-medium {
  font-size: 16px;
}

.font-smaller {
  font-size: 14px;
}

.text-separator {
  margin: 0 8px;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.cols {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -20px;
}
@media all and (max-width: 767px) {
  .cols {
    margin-left: -15px;
  }
}
.cols__item {
  box-sizing: border-box;
  flex: 0 1 auto;
  display: flex;
  padding-left: 20px;
}
@media all and (max-width: 767px) {
  .cols__item {
    padding-left: 15px;
  }
}
.cols__inner {
  width: 100%;
  box-sizing: border-box;
}
.cols__inner.has-b-margin {
  margin-bottom: 20px;
}

html, body {
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 1.8;
  color: #1f2022;
  -webkit-text-size-adjust: none;
}
body.is-fixed {
  width: 100%;
  max-height: 100%;
  position: fixed;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  body {
    font-size: 16px;
  }
}

h1, h2, h3, blockquote, h4, h5, h6 {
  margin: 0;
  line-height: 1.5;
}
h1:last-child, h2:last-child, h3:last-child, blockquote:last-child, h4:last-child, h5:last-child, h6:last-child {
  margin-bottom: 0;
}
h1 a, h2 a, h3 a, blockquote a, h4 a, h5 a, h6 a {
  color: inherit;
}

h1, h2, h3, blockquote {
  font-weight: bold;
  font-family: "PT Serif", serif;
  margin-bottom: 25px;
}
h1:last-child, h2:last-child, h3:last-child, blockquote:last-child {
  margin-bottom: 0;
}

h4, h5, h6 {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
}
h4:last-child, h5:last-child, h6:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 45px;
  margin-bottom: 15px;
}

h2 {
  font-size: 30px;
}

h3, blockquote {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
  font-weight: bold;
}

a {
  color: #ff7800;
  transition: color 0.2s;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

p, ul, ol, table, blockquote {
  margin: 0 0 20px;
}
p:last-child, ul:last-child, ol:last-child, table:last-child, blockquote:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

ul, ol {
  padding: 0 0 0 50px;
}
ul li, ol li {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}
table th, table td {
  text-align: center;
  padding: 9px 20px;
}
table th:first-child, table td:first-child {
  text-align: left;
}
table th {
  background: #f1f2f9;
}
table td {
  border: 1px solid #f1f2f9;
}

blockquote {
  padding-left: 60px;
  margin: 40px 0 40px 25%;
  position: relative;
}
blockquote:before {
  content: "";
  width: 38px;
  height: 27px;
  position: absolute;
  top: 8px;
  left: 0;
  background: url("../assets/icon-quotes.svg") center no-repeat;
}

@media all and (max-width: 767px) {
  h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 24px;
  }

  h3, blockquote {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h2, h3, blockquote {
    margin-bottom: 15px;
  }

  blockquote {
    margin: 30px 0;
  }
}
.page-holder {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  padding-top: 118px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.page-holder.has-compact-header {
  padding-top: 60px;
}
@media all and (max-width: 1023px) {
  .page-holder, .page-holder.has-compact-header {
    padding-top: 50px;
  }
}

.header {
  background: #10205b;
  font-size: 14px;
  letter-spacing: 0.05em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 118px;
  z-index: 10;
  display: flex;
  transition: height 0.3s;
}
.header.is-sticky, .header.is-compact {
  height: 60px;
}
.header a {
  color: white;
}
.header .wrapper {
  position: relative;
  height: 100%;
}
@media all and (max-width: 1023px) {
  .header, .header.is-sticky, .header.is-compact {
    height: 50px;
  }
}
.header__menu {
  margin-top: calc(118px - 60px);
  transition: margin-top 0.3s;
}
.has-sticky-header .header__menu {
  margin-top: 0;
}
@media all and (max-width: 1023px) {
  .header__menu, .has-sticky-header .header__menu {
    margin-top: 0;
  }
}
.header__inner {
  align-items: center;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}
.content__header {
  font-size: 16px;
}
.content__header-block {
  margin-top: 30px;
}
.content__header-block:first-child {
  margin-top: 0;
}
@media all and (max-width: 767px) {
  .content__header-block {
    margin-top: 15px;
    font-size: 14px;
  }
  .content__header-block .link {
    font-size: 14px;
  }
}
.content__block {
  padding: 50px 0 60px;
  overflow: hidden;
}
.content__block--medium {
  padding: 45px 0 35px;
}
.content__block--articles {
  padding: 0 0 60px;
}
.content__block.no-top-pad {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .content__block {
    padding: 25px 0 40px;
  }
  .content__block--medium {
    padding: 20px 0;
  }
}
.content__inner {
  margin-bottom: 40px;
}
.content__inner:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .content__inner {
    margin-bottom: 30px;
  }
}
.content__footer {
  padding: 22px 40px;
}
@media all and (max-width: 767px) {
  .content__footer {
    padding: 20px 15px;
    margin: 0 -15px;
  }
}

.footer {
  background: #10205b;
  font-size: 14px;
  color: white;
  padding: 22px 0 37px;
  margin-top: auto;
}
@media all and (max-width: 767px) {
  .footer {
    padding: 30px 0 50px;
  }
}
.footer__block {
  margin-bottom: 10px;
}
.footer__block:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .footer__block {
    margin-bottom: 15px;
  }
}
.footer__menu {
  font-size: 0;
}
.footer__item {
  font-size: 14px;
  display: inline-block;
  margin-left: 30px;
}
.has-separators .footer__item {
  position: relative;
}
.has-separators .footer__item:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -16px;
  width: 2px;
  height: 15px;
  margin-top: -8px;
  background: white;
}
.has-separators .footer__item:last-child:after {
  display: none;
}
.footer__item:first-child {
  margin-left: 0;
}
@media all and (max-width: 767px) {
  .footer__item {
    margin: 0 0 15px 0;
    display: block;
  }
  .footer__item:last-child {
    margin-bottom: 0;
  }
  .footer__item.is-mobile-inline-block {
    display: inline-block;
  }
  .footer__item.is-mobile-inline-block + .footer__item.is-mobile-inline-block.is-mobile-inline-block {
    margin-left: 30px;
  }
  .has-separators .footer__item:after {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: visibility 0s 0.5s, height 0s 0.5s, opacity 0.5s;
}
.overlay.is-visible {
  opacity: 1;
  visibility: visible;
  height: 100%;
  transition: visibility 0.5s, height 0s, opacity 0.5s;
  z-index: 10;
}
.overlay__inner {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.overlay__inner .wrapper {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 767px) {
  .overlay__inner {
    margin: 0 -15px;
    width: auto;
    min-height: 100%;
    background: white;
  }
}
.overlay__grid {
  position: relative;
}
.overlay__container {
  overflow: auto;
}
.overlay__header {
  padding: 65px 20px 20px;
  background: #193390;
  color: white;
  text-align: center;
  position: relative;
}
.overlay__close {
  font-size: 12px;
  padding: 12px 40px 12px 12px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: #193390;
  color: white;
  z-index: 10;
}
.overlay__close:before, .overlay__close:after {
  content: "";
  width: 1px;
  height: 18px;
  margin-right: 9px;
  background: white;
  position: absolute;
  top: 14px;
  right: 12px;
}
.overlay__close:before {
  transform: rotate(45deg);
}
.overlay__close:after {
  transform: rotate(-45deg);
}
.overlay__content {
  padding: 20px;
  background: white;
}
.overlay__content--no-pad {
  padding: 0;
}

.link-with-icon {
  position: relative;
}
.footer .link-with-icon, .header .link-with-icon {
  color: white;
}
.link-with-icon:hover {
  text-decoration: none;
}
.link-with-icon:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: no-repeat;
}
.link-with-icon--help {
  padding-left: 30px;
}
.link-with-icon--help:before {
  width: 20px;
  height: 20px;
  background-image: url("../assets/icon-help.svg");
  margin-top: -10px;
}
.link-with-icon--rss {
  padding-left: 26px;
}
.link-with-icon--rss:before {
  width: 16px;
  height: 16px;
  background-image: url("../assets/icon-rss.svg");
  margin-top: -8px;
}
.link-with-icon--login {
  padding-left: 29px;
}
.link-with-icon--login:before {
  width: 16px;
  height: 21px;
  background-image: url("../assets/icon-login.svg");
  margin-top: -10px;
}
.link-with-icon--search {
  padding-left: 36px;
}
.link-with-icon--search:before {
  width: 20px;
  height: 20px;
  background-image: url("../assets/icon-search.svg");
  margin-top: -10px;
}
.link-with-icon--download {
  padding-left: 30px;
}
.link-with-icon--download:before {
  width: 14px;
  height: 17px;
  background-image: url("../assets/icon-download.svg");
  margin-top: -8px;
}
.link-with-icon--question {
  padding-left: 34px;
}
.link-with-icon--question:before {
  width: 20px;
  height: 20px;
  background-image: url("../assets/icon-question.svg");
  margin-top: -10px;
}
.link-with-icon--logged-in {
  padding-left: 28px;
}
.link-with-icon--logged-in:before {
  width: 16px;
  height: 21px;
  background-image: url("../assets/icon-logged-in.svg");
  margin-top: -11px;
}
.link-with-icon--logout {
  padding-left: 30px;
}
.link-with-icon--logout:before {
  width: 18px;
  height: 18px;
  background-image: url("../assets/icon-logout.svg");
  margin-top: -9px;
}
.link-with-icon--user {
  padding-left: 32px;
}
.link-with-icon--user:before {
  width: 20px;
  height: 20px;
  background-image: url("../assets/icon-user.svg");
  margin-top: -10px;
}
.link-with-icon--settings {
  padding-left: 31px;
}
.link-with-icon--settings:before {
  width: 19px;
  height: 20px;
  background-image: url("../assets/icon-settings.svg");
  margin-top: -10px;
}

.logo {
  display: inline-block;
  margin: 8px 0 0;
}
@media all and (max-width: 1279px) {
  .logo {
    margin-top: 17px;
  }
  .header.is-sticky .logo, .header.is-compact .logo {
    margin-top: 5px;
  }
}
@media all and (max-width: 1023px) {
  .logo, .header.is-sticky .logo, .header.is-compact .logo {
    margin-top: 5px;
  }
}
.logo__symbol {
  width: 186px;
  height: 41px;
  background: url("../assets/logo.svg") top center/contain no-repeat;
  margin: 0 auto;
  transition: all 0.3s;
}
.header.is-sticky .logo__symbol, .header.is-compact .logo__symbol {
  width: 145px;
  height: 32px;
}
@media all and (max-width: 1279px) {
  .logo__symbol, .header.is-sticky .logo__symbol, .header.is-compact .logo__symbol {
    width: 145px;
    height: 32px;
  }
}
@media all and (max-width: 1023px) {
  .logo__symbol, .header.is-sticky .logo__symbol, .header.is-compact .logo__symbol {
    width: 94px;
    height: 21px;
  }
}
.logo__text {
  margin-top: 10px;
  font-size: 12px;
}
.header.is-sticky .logo__text, .header.is-compact .logo__text {
  display: none;
}
@media all and (max-width: 1023px) {
  .logo__text {
    display: none;
  }
}

.mainmenu {
  font-size: 0;
}
@media all and (max-width: 1023px) {
  .mainmenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    box-sizing: border-box;
    padding: 65px 0;
    background: #193390;
    overflow: auto;
    display: none;
  }
  .mainmenu.is-visible {
    display: block;
  }
}
.mainmenu__item {
  font-size: 14px;
  padding: 0 25px;
  display: inline-block;
  height: 100%;
  position: relative;
  line-height: 60px;
  z-index: 1;
}
.mainmenu__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #193390;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: visibility 0s 0.2s, height 0s 0.2s, opacity 0.2s;
}
.mainmenu__item:first-child {
  margin-left: -25px;
}
.mainmenu__item:last-child {
  margin-right: -25px;
}
.mainmenu__item.is-active:before {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s, height 0s, opacity 0.2s;
}
.mainmenu__item--lang {
  display: none;
}
@media all and (max-width: 1279px) {
  .mainmenu__item {
    padding: 0 12px;
  }
  .mainmenu__item:first-child {
    margin-left: -12px;
  }
  .mainmenu__item:last-child {
    margin-right: -12px;
  }
}
@media all and (max-width: 1023px) {
  .mainmenu__item {
    line-height: 1.8;
    display: block;
    height: auto;
    padding: 13px 0 0;
    font-size: 30px;
    font-family: "PT Serif", serif;
    margin: 0 30px;
  }
  .mainmenu__item:first-child {
    margin-left: 30px;
  }
  .mainmenu__item:last-child {
    margin-right: 30px;
  }
  .mainmenu__item--lang {
    display: block;
    margin-top: 45px;
  }
}
.mainmenu__link {
  position: relative;
}
@media all and (max-width: 1023px) {
  .mainmenu__link {
    border-bottom: 1px solid #5869a5;
    display: block;
  }
  .mainmenu__item--lang .mainmenu__link {
    border-bottom: none;
  }
}
.mainmenu__close {
  position: fixed;
  left: 0;
  top: 0;
  padding: 12px 16px 12px 47px;
  color: white;
  background: #193390;
  font-size: 14px;
  z-index: 10;
  display: none;
}
.mainmenu__close:before, .mainmenu__close:after {
  content: "";
  width: 1px;
  height: 22px;
  margin-right: 15px;
  background: white;
  position: absolute;
  top: 50%;
  left: 24px;
  margin-top: -11px;
}
.mainmenu__close:before {
  transform: rotate(45deg);
}
.mainmenu__close:after {
  transform: rotate(-45deg);
}
@media all and (max-width: 1023px) {
  .mainmenu__close {
    display: block;
  }
}
.mainmenu__submenu-toggler {
  position: absolute;
  top: 20px;
  right: 0;
  width: 26px;
  height: 26px;
  margin-top: -13px;
  display: none;
}
@media all and (max-width: 1023px) {
  .mainmenu__submenu-toggler {
    display: block;
  }
}
.mainmenu__submenu-toggler:before, .mainmenu__submenu-toggler:after {
  content: "";
  width: 100%;
  height: 1px;
  background: white;
  position: absolute;
}
.mainmenu__submenu-toggler:before {
  top: 50%;
  left: 0;
}
.mainmenu__submenu-toggler:after {
  left: 0;
  top: 50%;
  margin-left: -1px;
  transform: rotate(90deg);
}
.has-submenu-open .mainmenu__submenu-toggler:after, .has-thirdmenu-open .mainmenu__submenu-toggler:after {
  display: none;
}

.submenu {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  padding: 30px 0;
  display: none;
}
.submenu.is-visible {
  display: block;
}
.submenu__item {
  margin-bottom: 18px;
  position: relative;
}
.submenu__item:last-child {
  margin-bottom: 0;
}
.is-active .submenu__link {
  color: #ff7800;
}

.thirdmenu {
  margin: 10px -30px 0;
  padding: 5px 0;
  background: #1f3fb0;
  line-height: 1.5;
}
.thirdmenu__item {
  padding: 15px 30px 15px 60px;
}
@media all and (max-width: 1023px) {
  .thirdmenu {
    display: none;
  }
  .has-thirdmenu-open .thirdmenu {
    display: block;
  }
}

.mobilemenu-open {
  display: none;
  color: white;
  align-items: center;
}
.mobilemenu-open__burger {
  width: 19px;
  height: 1px;
  position: relative;
  background: white;
  margin-right: 12px;
}
.mobilemenu-open__burger:before, .mobilemenu-open__burger:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: white;
}
.mobilemenu-open__burger:before {
  top: -7px;
}
.mobilemenu-open__burger:after {
  bottom: -7px;
}
@media all and (max-width: 1023px) {
  .mobilemenu-open {
    display: flex;
  }
}

.settings-menu {
  font-size: 0;
}
@media all and (max-width: 1023px) {
  .settings-menu {
    margin-right: -15px;
    display: flex;
    justify-content: flex-end;
  }
}
.settings-menu__item {
  font-size: 14px;
  padding: 0 17px;
  display: inline-block;
  height: 100%;
  position: relative;
  line-height: 60px;
  z-index: 1;
}
.settings-menu__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #193390;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: visibility 0s 0.2s, height 0s 0.2s, opacity 0.2s;
}
.settings-menu__item:first-child {
  margin-left: -17px;
}
.settings-menu__item:last-child {
  margin-right: -17px;
}
.settings-menu__item.is-active:before {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s, height 0s, opacity 0.2s;
}
@media all and (max-width: 1279px) {
  .settings-menu__item {
    padding: 0 12px;
  }
  .settings-menu__item:first-child {
    margin-left: -12px;
  }
  .settings-menu__item:last-child {
    margin-right: -12px;
  }
}
@media all and (max-width: 1023px) {
  .settings-menu__item {
    padding: 0;
    height: auto;
    line-height: 50px;
  }
  .settings-menu__item:first-child, .settings-menu__item:last-child {
    margin: 0;
  }
  .settings-menu__item--lang {
    display: none;
  }
}
@media all and (max-width: 1023px) {
  .settings-menu__link {
    display: block;
    width: 21px;
    padding: 0 15px;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
  }
  .settings-menu__link:before {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.user-settings {
  position: absolute;
  top: 100%;
  right: 0;
  background: #193390;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  transition: visibility 0s 0.2s, height 0.2s, opacity 0s 0.2s;
}
.user-settings.is-visible {
  height: 40px;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.2s, height 0.2s, opacity 0.2s;
}
@media all and (max-width: 767px) {
  .user-settings {
    position: fixed;
    top: 50px;
    text-align: left;
    width: 100%;
    transition: visibility 0s 0.2s, height 0.2s, opacity 0s 0.2s, padding 0.2s;
  }
  .user-settings.is-visible {
    padding: 15px 0;
    height: 150px;
    transition: visibility 0.2s, height 0.2s, opacity 0.2s, padding 0.2s;
  }
}
.user-settings__menu {
  display: flex;
  padding: 0 15px;
}
@media all and (max-width: 767px) {
  .user-settings__menu {
    flex-direction: column;
  }
}
.user-settings__item {
  margin-left: 30px;
}
.user-settings__item:first-child {
  margin-left: 0;
}
@media all and (max-width: 767px) {
  .user-settings__item {
    margin: 0;
  }
}

.search {
  width: calc(100% - 2 * 3px);
  height: 0;
  position: absolute;
  top: 100%;
  left: 3px;
  background: #193390;
  color: white;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  box-sizing: border-box;
  letter-spacing: 0;
  transition: visibility 0s 0.2s, height 0.2s, opacity 0s 0.2s;
}
.search.is-visible {
  height: 216px;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.2s, height 0.2s, opacity 0.2s;
}
.search.is-compact {
  height: 65px;
}
@media all and (max-width: 1274px) {
  .search {
    width: 100%;
    left: 0;
  }
}
@media all and (max-width: 767px) {
  .search {
    width: 100%;
    left: 0;
    padding: 0;
  }
  .search.is-visible {
    height: 150px;
  }
  .search.is-compact {
    height: 65px;
  }
}
.search__inner {
  padding: 60px 0 50px;
  transition: padding 0.2s;
}
.is-compact .search__inner {
  padding: 10px 0;
}
@media all and (max-width: 767px) {
  .search__inner {
    padding: 20px 0;
  }
}
.search__block {
  margin-bottom: 40px;
}
.search__block:last-child {
  margin-bottom: 0;
}
.is-compact .search__block {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .search__block {
    margin-bottom: 30px;
    padding: 0 15px;
  }
}
.search__input {
  background: none;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  width: 100%;
  padding: 5px 0;
  font-size: 30px;
  font-family: "PT Serif", serif;
  color: white;
  height: 40px;
  box-sizing: border-box;
  font-style: italic;
  transition: font-size 0.2s;
}
.is-compact .search__input {
  font-size: 24px;
}
.search__input:focus {
  outline: none;
}
.search__input::placeholder {
  color: white;
  font-style: italic;
}
@media all and (max-width: 767px) {
  .search__input {
    font-size: 24px;
  }
}
@media all and (max-width: 374px) {
  .search__input {
    font-size: 20px;
  }
}
.search__btn {
  transition: margin-top 0.2s;
}
@media all and (max-width: 767px) {
  .search__btn {
    width: 100%;
  }
}
.is-compact .search__options {
  display: none;
}
.search__radio {
  display: inline-block;
  margin-left: 25px;
}
.search__radio:first-child {
  margin-left: 0;
}
@media all and (max-width: 767px) {
  .search__radio {
    margin-left: 18px;
  }
}

.sitemenu {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  background: #193390;
  color: white;
  padding: 70px 0 0 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  z-index: -1;
  transition: visibility 0s 1s, z-index 0s 1s, left 1s;
}
.sitemenu.is-visible {
  z-index: 10;
  left: 0;
  visibility: visible;
  transition: visibility 0.5s, left 0.5s;
}
.sitemenu h2 {
  margin-bottom: 45px;
  margin-right: 45px;
  font-weight: normal;
}
.sitemenu__item:last-child {
  margin-bottom: 25px;
}
.sitemenu__link {
  position: relative;
  padding: 10px 45px 10px 0;
  display: block;
}
.sitemenu__link:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  transition: background 0.2s;
}
.sitemenu__link:hover:after, .is-active > .sitemenu__link:after {
  background: #e7ebf5;
}
.is-active .sitemenu__link {
  color: #ff7800;
}
.sitemenu__close {
  font-size: 14px;
  padding-right: 30px;
  position: absolute;
  top: 17px;
  right: 12px;
  cursor: pointer;
  color: #9fa7bd;
}
.sitemenu__close:before, .sitemenu__close:after {
  content: "";
  width: 1px;
  height: 22px;
  margin-right: 11px;
  background: #9fa7bd;
  position: absolute;
  top: 1px;
  right: 0;
}
.sitemenu__close:before {
  transform: rotate(45deg);
}
.sitemenu__close:after {
  transform: rotate(-45deg);
}
.sitemenu__cols {
  flex: 1;
  overflow: hidden;
  margin-left: 0;
}
.sitemenu__cols-item {
  max-width: 325px;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}
.sitemenu__cols-item:first-child {
  border-left: none;
  padding-left: 0;
  flex: 1;
}
.sitemenu__thirdmenu {
  display: none;
}
.sitemenu__thirdmenu-holder .sitemenu__thirdmenu {
  display: block;
  padding-left: 25px;
}
.sitemenu__thirdmenu-holder {
  border-left: 1px solid white;
}
.sitemenu__thirdmenu-holder:empty {
  border-left: none;
}

.form__block {
  margin-bottom: 40px;
}
.form__block:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .form__block {
    margin-bottom: 15px;
  }
}
.form__field-holder {
  display: flex;
  flex-direction: column;
}
.form__field {
  margin-top: auto;
  margin-bottom: 22px;
  position: relative;
}
.form__submit {
  margin-top: 30px;
}
.form__submit:first-child {
  margin-top: 0;
}
.form__btn {
  -webkit-appearance: none;
}
.form__label {
  font-size: 14px;
  color: #9fa7bd;
  display: block;
  margin-bottom: 7px;
}
.is-disabled .form__label {
  color: #e1e3ef;
}
.has-errors .form__label {
  color: #ee0b0b;
}
.form__input-holder {
  position: relative;
}
.has-errors .form__input-holder:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background: url("../assets/icon-error.svg") center no-repeat;
}
.form__input, .form__textarea {
  background: #f1f2f9;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #f1f2f9;
  border-radius: 0;
  width: 100%;
  color: #1f2022;
  position: relative;
  display: block;
  transition: border-color 0.2s;
  -webkit-appearance: none;
}
.form__input:focus, .form__textarea:focus {
  border-color: #9fa7bd;
  outline: none;
}
.form__input:disabled, .form__textarea:disabled {
  background: white;
  border-color: #e1e3ef;
}
.has-errors .form__input, .has-errors .form__textarea {
  padding-right: 40px;
}
.form__textarea {
  height: 110px;
}
.form__radio, .form__checkbox {
  position: relative;
  padding-left: 30px;
  font-size: 14px;
}
.form__radio label, .form__checkbox label {
  cursor: pointer;
}
.form__radio--primary, .form__checkbox--primary {
  color: #9fa7bd;
}
.form__radio--secondary, .form__checkbox--secondary {
  color: white;
}
.form__radio-input, .form__checkbox-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.form__radio-icon, .form__checkbox-icon {
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
}
.form__radio--primary .form__radio-icon, .form__checkbox--primary .form__radio-icon, .form__radio--primary .form__checkbox-icon, .form__checkbox--primary .form__checkbox-icon {
  background: #f1f2f9;
  border-color: #f1f2f9;
}
.form__radio--secondary .form__radio-icon, .form__checkbox--secondary .form__radio-icon, .form__radio--secondary .form__checkbox-icon, .form__checkbox--secondary .form__checkbox-icon {
  background: none;
  border-color: white;
}
.form__radio-input:checked + .form__radio-icon:after, .form__checkbox-input:checked + .form__radio-icon:after, .form__radio-input:checked + .form__checkbox-icon:after, .form__checkbox-input:checked + .form__checkbox-icon:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -5px;
}
.form__radio--primary .form__radio-input:checked + .form__radio-icon:after, .form__checkbox--primary .form__checkbox-input:checked + .form__radio-icon:after, .form__radio--primary .form__radio-input:checked + .form__checkbox-icon:after, .form__checkbox--primary .form__checkbox-input:checked + .form__checkbox-icon:after {
  background: #9fa7bd;
}
.form__radio--secondary .form__radio-input:checked + .form__radio-icon:after, .form__checkbox--secondary .form__checkbox-input:checked + .form__radio-icon:after, .form__radio--secondary .form__radio-input:checked + .form__checkbox-icon:after, .form__checkbox--secondary .form__checkbox-input:checked + .form__checkbox-icon:after {
  background: white;
}
.form__radio-icon {
  border-radius: 50%;
}
.form__radio-input:checked + .form__radio-icon:after {
  border-radius: 50%;
}
.form__error {
  color: #ee0b0b;
  position: relative;
  font-size: 12px;
}

.btn {
  display: inline-block;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 30px;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.8;
  text-transform: uppercase;
  text-align: center;
  padding: 7px 25px;
  cursor: pointer;
  transition: background 0.2s, color 0.2s;
}
.btn:focus {
  outline: none;
}
.btn:hover {
  text-decoration: none;
}
.btn--primary {
  border-color: #ff7800;
  background: none;
  color: #ff7800;
}
.btn--primary:hover {
  background: #ff7800;
  color: white;
}
.btn--primary:active {
  background: #f26100;
  color: white;
}
.btn--primary:disabled, .btn--primary:disabled:hover {
  border-color: #ffcb9e;
  color: #ffcb9e;
  background: white;
  cursor: default;
}
.btn--secondary {
  border-color: #9fa7bd;
  background: none;
  color: #9fa7bd;
}
.btn--secondary:hover {
  background: #9fa7bd;
  color: white;
}
.btn--secondary:active {
  background: #848ea7;
  color: white;
}
.btn--secondary:disabled, .btn--secondary:disabled:hover {
  border-color: #e1e3ef;
  color: #e1e3ef;
  background: white;
  cursor: default;
}
.btn--third {
  border-color: white;
  background: none;
  color: white;
}
.btn--third:hover {
  background: white;
  color: #193390;
}
.btn--third:active {
  background: #e1e3ef;
  border-color: #e1e3ef;
  color: #193390;
}
.btn--third:disabled, .btn--third:disabled:hover {
  border-color: #9fa7bd;
  color: #9fa7bd;
  background: none;
  cursor: default;
}
.btn--big {
  padding: 21px;
  border-radius: 35px;
  width: 100%;
}
.btn__icon {
  vertical-align: middle;
  margin: -2px 12px 0 0;
  fill: #ff7800;
  transition: fill 0.2s;
}
.btn:hover .btn__icon {
  fill: white;
}

.tabs__header {
  font-size: 0;
  display: flex;
  border: 1px solid #f1f2f9;
}
.tabs__item {
  display: inline-block;
  font-size: 14px;
  flex: 1 1 auto;
  border-left: 1px solid white;
}
.tabs__item:first-child {
  border-left: none;
}
@media all and (max-width: 374px) {
  .tabs__item {
    font-size: 13px;
  }
}
.tabs__link {
  color: #1f2022;
  display: block;
  padding: 12px 10px 11px;
  text-align: center;
  background: #f1f2f9;
  transition: background 0.2s;
  box-sizing: border-box;
  height: 100%;
}
.is-active .tabs__link {
  background: white;
}
@media all and (max-width: 374px) {
  .tabs__link {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.tabs__body {
  background: white;
}
.tabs__inner {
  padding: 20px 0;
  background: white;
}
.tabs__content {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  height: 0;
}
.tabs__content.is-active {
  opacity: 1;
  visibility: visible;
  height: auto;
  transition: visibility 0.5s, height 0.5s, opacity 0.5s;
}

.overlay .login .btn {
  width: 100%;
}
.login__content {
  padding: 35px 0;
  min-height: 307px;
  box-sizing: border-box;
}
.overlay .login__content {
  padding: 35px 85px 65px;
  min-height: 335px;
}
@media all and (max-width: 767px) {
  .overlay .login__content {
    padding: 25px 15px;
  }
}

.articles .link {
  margin-bottom: 15px;
}
@media all and (max-width: 1023px) {
  .articles h2 {
    font-size: 24px;
  }
  .articles h3, .articles blockquote {
    font-size: 20px;
  }
  .articles .link {
    margin-bottom: 5px;
  }
}
@media all and (max-width: 767px) {
  .articles {
    flex-direction: column;
    margin-bottom: -5px;
  }
}
.articles__link {
  padding: 40px 50px;
  background: white;
  color: #121212;
  display: block;
}
.articles__link:hover {
  text-decoration: none;
}
.articles__link--highlight {
  color: #10205b;
  background: white url("../images/bg-pattern.png") center -100px no-repeat;
}
.articles__link--featured {
  padding-top: 65px;
  padding-bottom: 65px;
}
@media all and (max-width: 1023px) {
  .articles__link {
    padding: 30px 20px;
  }
}
@media all and (max-width: 767px) {
  .articles__link {
    padding: 20px 15px;
  }
  .articles__link.has-b-margin {
    margin-bottom: 5px;
  }
}
@media all and (max-width: 767px) {
  .articles__item {
    width: auto;
    margin: 0 -20px;
  }
}
.articles__btn {
  width: 50%;
}

.article__highlight {
  transition: box-shadow 0.2s;
  position: relative;
}
.article__highlight:hover {
  box-shadow: inset 0 -8px 0 0px #ffddbf;
}
.article__tooltip:hover {
  text-decoration: none;
}
.article__nav {
  font-size: 24px;
  font-family: "PT Serif", serif;
}
@media all and (max-width: 767px) {
  .article__nav, .article__nav:last-child {
    margin-bottom: -20px;
  }
}
@media all and (max-width: 767px) {
  .article__link-block {
    margin-bottom: 20px;
  }
  .article__link-block:last-child {
    margin-bottom: 0;
  }
}
.article__prev-link {
  position: relative;
}
.article__prev-link:before, .article__prev-link:after {
  content: "";
  width: 2px;
  height: 13px;
  background: #ff7800;
  position: absolute;
  top: 5px;
  left: -25px;
}
.article__prev-link:before {
  transform: rotate(45deg);
}
.article__prev-link:after {
  transform: rotate(-45deg);
  margin-top: 8px;
}
@media all and (max-width: 767px) {
  .article__prev-link:before, .article__prev-link:after {
    left: 5px;
  }
}
.article__next-link {
  position: relative;
}
.article__next-link:before, .article__next-link:after {
  content: "";
  width: 2px;
  height: 13px;
  background: #ff7800;
  position: absolute;
  top: 5px;
  right: -25px;
}
.article__next-link:before {
  transform: rotate(-45deg);
}
.article__next-link:after {
  transform: rotate(45deg);
  margin-top: 8px;
}
@media all and (max-width: 767px) {
  .article__next-link:before, .article__next-link:after {
    right: 5px;
  }
}
@media all and (max-width: 767px) {
  .article__prev-link, .article__next-link {
    text-indent: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 12px;
  }
}
@media all and (max-width: 767px) and (max-width: 767px) {
  .article__prev-link:before, .article__prev-link:after, .article__next-link:before, .article__next-link:after {
    top: 12px;
  }
}

.tags {
  font-size: 0;
  margin-bottom: -10px;
}
.tags__item {
  display: inline-block;
  font-size: 14px;
  margin: 0 10px 10px 0;
}
.tags__item:last-child {
  margin-right: 0;
}
.tags__link {
  background: white;
  border: 1px solid #9fa7bd;
  display: block;
  padding: 5px 12px;
  color: #9fa7bd;
  border-radius: 20px;
  transition: color 0.2s, background 0.2s;
}
.tags__link:hover {
  background: #9fa7bd;
  color: white;
}

.footnotes {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
}
.footnotes:last-child {
  margin-bottom: 0;
}
.footnotes__item {
  margin-bottom: 10px;
}

.archive .link {
  margin-bottom: 15px;
}
@media all and (max-width: 767px) {
  .archive .link {
    margin-bottom: 10px;
  }
}
.archive__item {
  margin-bottom: 40px;
}
.archive__item:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .archive__item {
    margin-bottom: 30px;
  }
}
.archive__link {
  color: #1f2022;
}

@media all and (max-width: 767px) {
  .register {
    padding: 15px 0;
  }
}
.register__header {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.register__header a {
  color: #1f2022;
}
@media all and (max-width: 767px) {
  .register__header {
    flex-direction: column;
  }
}
.register__header-block {
  align-self: center;
}
@media all and (max-width: 767px) {
  .register__header-block {
    align-self: flex-start;
  }
  .register__header-block:last-child {
    align-self: flex-end;
    margin-bottom: 10px;
    order: -1;
  }
}
.register__email-block {
  background: #10205b;
  color: white;
  padding: 40px 0;
}
@media all and (max-width: 767px) {
  .register__email-block {
    background: #f1f2f9;
    color: #1f2022;
    margin: 0 -15px;
    width: calc(100% + 2 * 15px);
    padding: 40px 15px 40px 30px;
    box-sizing: border-box;
  }
  .register__email-block .form__input {
    border-color: #aab1c5;
  }
}
.register__title {
  margin-bottom: 30px;
}

.prefilled-data {
  margin-bottom: 40px;
}
@media all and (max-width: 767px) {
  .prefilled-data {
    margin-bottom: 0;
  }
}
.prefilled-data__row {
  margin-bottom: 8px;
}
.prefilled-data__row:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .prefilled-data__row, .prefilled-data__row:last-child {
    margin-bottom: 15px;
  }
}
.prefilled-data__label {
  font-size: 14px;
  color: #9fa7bd;
  border-bottom: 1px solid #9fa7bd;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.banks {
  margin-bottom: -20px;
}
.banks__item {
  margin-bottom: 20px;
}
.banks__label {
  position: relative;
  display: block;
}
.banks__radio {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  margin-top: -3px;
}
.banks__link {
  border: 1px solid #9fa7bd;
  border-radius: 40px;
  padding: 11px 20px;
  height: 60px;
  display: block;
  text-indent: -100%;
  overflow: hidden;
  cursor: pointer;
  background: center no-repeat;
  box-sizing: border-box;
  transition: background-color 0.2s, border 0.2s;
}
.banks__link:hover, .banks__radio:checked + .banks__link {
  background-color: #f1f2f9;
  border-color: #f1f2f9;
}
.banks__link--swed {
  background-image: url("../assets/logo-swed.svg");
  background-size: 127px 26px;
}
.banks__link--seb {
  background-image: url("../assets/logo-seb.svg");
}
.banks__link--nordea {
  background-image: url("../assets/logo-nordea.svg");
}
.banks__link--lhv {
  background-image: url("../assets/logo-lhv.svg");
}
.banks__link--text {
  font-size: 16px;
  color: #1f2022;
  text-indent: 0;
  text-align: center;
  line-height: 38px;
}

.order-steps {
  counter-reset: steps;
  margin-top: 50px;
}
@media all and (max-width: 767px) {
  .order-steps {
    margin-top: 20px;
  }
}
.order-steps__item {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  flex-grow: 1;
  position: relative;
}
@media all and (max-width: 767px) {
  .order-steps__item:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #cbcfdd;
  }
  .order-steps__item:first-child:before {
    left: 15px;
  }
}
.order-steps__link {
  display: flex;
  align-items: center;
  color: #9fa7bd;
}
.order-steps__link:hover {
  text-decoration: none;
}
.is-active .order-steps__link {
  color: #ff7800;
}
.order-steps__item + .order-steps__item .order-steps__link {
  justify-content: center;
}
.order-steps__item:last-child .order-steps__link {
  justify-content: flex-end;
}
.order-steps__number {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  border: 1px solid #9fa7bd;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  background: #f1f2f9;
  position: relative;
}
.is-active .order-steps__number {
  color: #ff7800;
  border-color: #ff7800;
}
.order-steps__text {
  margin-left: 8px;
}
@media all and (max-width: 767px) {
  .order-steps__text {
    display: none;
  }
}

@media all and (max-width: 767px) {
  .variations {
    margin: 0 -15px;
  }
}
.variations__item {
  border-bottom: 1px solid #f1f2f9;
}
.variations__item:first-child {
  border-top: 1px solid #f1f2f9;
}
.variations__label {
  position: relative;
  display: block;
}
.variations__radio {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -3px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.variations__row {
  padding: 30px 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.variations__row:after {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 0;
  height: 100%;
  background-color: #f1f2f9;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s;
}
.variations__row:hover:after, .variations__radio:checked + .variations__row:after {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .variations__row {
    padding: 10px 15px;
  }
  .variations__row:after {
    left: 15px;
  }
}
.variations__col {
  align-items: center;
}
.variations__col--with-pad {
  padding-right: 12.5%;
}
.variations__col:first-child {
  padding-left: 55px;
}
@media all and (max-width: 767px) {
  .variations__col--with-pad {
    padding-right: 0;
  }
  .variations__col:first-child {
    padding-left: 15px;
  }
}
.variations__check {
  width: 30px;
  height: 30px;
  background: #193390 url("../assets/icon-check-small.svg") center no-repeat;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  margin: 0 auto;
}
.variations__radio:checked + .variations__row .variations__check {
  opacity: 1;
  visibility: visible;
}
@media all and (max-width: 767px) {
  .variations__check {
    width: 20px;
    height: 20px;
    background-size: 10px auto;
    margin-right: 0;
  }
}
.variations__submit {
  margin-top: 60px;
}
@media all and (max-width: 767px) {
  .variations__submit {
    padding: 0 15px;
    margin-top: 40px;
  }
}

.search-results h3, .search-results blockquote {
  margin-bottom: 15px;
}
.search-results__item {
  margin-bottom: 55px;
}
.search-results__item:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .search-results__item {
    margin-bottom: 40px;
  }
}
.search-results__content {
  margin-top: 25px;
  font-style: italic;
}

.search-page .content {
  transition: padding-top 0.3s;
}
.search-page.has-search-open .content {
  padding-top: 216px;
}
@media all and (max-width: 767px) {
  .search-page.has-search-open .content {
    padding-top: 150px;
  }
}

.tipso_style {
  border: none;
  cursor: pointer;
}
.tipso_bubble {
  box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  font-size: 14px;
}
.tipso_bubble > .tipso_arrow {
  border: none;
  width: 14px;
  height: 14px;
  background: white;
  transform: rotate(-45deg);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  z-index: -1;
}
.tipso_bubble.bottom {
  margin-top: 30px;
}
.article-page .tipso_bubble.bottom {
  margin-left: 5px;
}
.tipso_bubble.bottom > .tipso_arrow {
  margin-bottom: -7px;
}
.tipso_bubble.top {
  margin-top: -10px;
}
.tipso_bubble.top > .tipso_arrow {
  margin-top: -7px;
}
.tipso_bubble.left {
  margin-left: -6px;
  margin-top: 10px;
}
.article-page .tipso_bubble.left {
  margin-top: 14px;
}
.tipso_bubble.left > .tipso_arrow {
  margin-left: -7px;
}
.tipso_bubble.right {
  margin-left: 26px;
  margin-top: 10px;
}
.article-page .tipso_bubble.right {
  margin-top: 14px;
}
.tipso_bubble.right > .tipso_arrow {
  margin-right: -7px;
}
.tipso_content {
  padding: 30px;
  text-align: left;
  position: relative;
  z-index: 1;
  background: white;
}
